.page-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    height: 100%;
    width: 100%;
    /* flex: 1; */
  }
  
  .page-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .page {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
    color: #333;
    font-size: 24px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .page.active {
    z-index: 1;
  }
  