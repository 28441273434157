.hero {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 30px;
}

.hero_container {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    border-radius: 30px;
}

.hero_image_mockup {
    display: block;
    margin: auto;
    height: 90%;
    max-width: 100%;
    padding-top: 30px;
}

.hero_content {
    background: linear-gradient(to bottom, rgba(255,252,192,0), rgba(255,252,192,1) 60%);
    padding: 80px 10px 40px 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.about {
    text-align: center;
}

.about .body-small { margin: auto; }

@media (min-width: 500px) {
    .about .body-small {
        max-width: 400px;
    }
}

@media (min-width: 768px) {
    .about .body-small {
        max-width: 600px;
    }
}

@media (min-width: 900px) {
    .hero_container {
        display: flex;
        align-items: center;
    }

    .hero_content {
        width: 50%;
        text-align: left;
        position: static;
    }
}