/* Button */
.button {
    display: inline-block;
    padding: 16px 24px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 15px;
    background-color: #222;
    transition: background-color 0.3s ease;
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.button.secondary {
    color: #333;
    border: 2px solid #333;
    background-color: white;
}

.text-button {
    padding: 8px 20px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    font-weight: 500;
    font-size: 16px;
    color: red;
}

.wide {
    width: 100%;
}

.button:hover {
    background-color: #444;
}

/* Divider */
.divider {
    height: 1px;
    width: 100%;
    background-color: #d9d9d9;
}