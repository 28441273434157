.checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  input[type="checkbox"] {
    margin-right: 8px;
  }
  
  input[type="checkbox"]:checked {
    background-color: green;
    color: white;
  }
  