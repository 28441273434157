.packages {
    text-align: center;
}

.card {
    text-align: left;
    border: 1px solid #d9d9d9;
    padding: 20px;
    border-radius: 30px;
}

.card-image {
    width: 100%;
}

.title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-title {
    font-weight: 600;
    letter-spacing: -0.5px;
}

.price {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -2px;
}

.benefits {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.card-subheader {
    font-weight: 500;
}

.list-item {
    display: flex; 
    gap: 10px;
    align-items: center;
}

@media (min-width: 500px) {
    .card {
        display: flex;
    }

    .card-image-wrapper {
        width: 50%;
        margin-right: 30px;
    }

    .card-content {
        width: 50%;
        /* flex-grow: 1; */
    }
}

@media (min-width: 1024px) {
    .card {
        display: block;
    }

    .package-list {
        display: flex;
        gap: 10px;
    }

    .card-content, .card-image-wrapper {
        width: auto;
        margin-right: 0;
    }
}

@media (min-width: 1200px) {
    .package-list {
        display: flex;
        gap: 25px;
    }
}