/* Reset default margins and paddings */
body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
  /* font-family: 'Inter', sans-serif; */
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

/* Container styles */
/* .container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1000px;
} */

/* Media query for screens 500px wide or less */
@media (max-width: 500px) {
  .container {
      margin-left: 10px;
      margin-right: 10px;
  }
}

/* Media query for screens 500px wide or more */
@media (min-width: 500px) {
  .container {
      margin-left: 35px;
      margin-right: 35px;
  }
}

/* Media query for screens 1024px wide or more */
@media (min-width: 1024px) {
  .container {
      margin-left: auto;
      margin-right: auto;
      width: 1000px !important;
  }
}

/* Media query for screens 1200px wide or more */
@media (min-width: 1200px) {
  .container {
      margin-left: auto;
      margin-right: auto;
      width: 1100px !important;
  }
}