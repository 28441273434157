.purchase-form-container {
    height: 100vh;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.purchase-form-container .container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.page-heading {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}

/* Form styles */
form.form {
    padding: 20px 0;
    height: 100%;
    min-width: 80%;
}

.step {
    font-weight: 400;
}

.remove-button {
    margin-left: 20px;
}

/* Buttons to control form */
.button-row {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

/* Loading component */
.loading {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    width: 100%;
}

.loading-text {
    display: flex;
    align-items: center;
    gap: 15px;
}

/* Adjust form width according to screen size */
@media (min-width: 500px) {

    .page-view,
    .loading,
    .button-row {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .page-container {
        border: 1px solid #d9d9d9;
        border-radius: 20px;
    }
}

@media (min-width: 1024px) {

    .page-view,
    .loading,
    .button-row {
        width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
}