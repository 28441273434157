footer {
    background-color: #333;
    padding: 20px 0;
    text-align: center;
}

footer a {
    color: #a7a7a7;
    text-decoration: none;
    border-bottom: 2px solid #a7a7a7;
}