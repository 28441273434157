.navbar {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    background-color: white;
    height: 80px;
    padding: 0 20px;
}

.navbar-logo {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1px;
}

.navbar-links {
    display: flex;
    gap: 20px;
}

.navbar-links a {
    color: #333;
    text-decoration: none;
}

.navbar-mobile-menu {
    display: none;
}

/* Media query for screens 700px wide or less */
@media (max-width: 700px) {
    .navbar-links {
        display: none;
    }

    .navbar-mobile-menu {
        display: block;
    }

    .menu-icon {
        padding: 16px 24px;
        font-size: 14px;
        font-family: "Inter";
        background-color: #333;
        color: white;
        border-radius: 50px;
        border: 0;
        transition: background-color 0.3s ease;
    }

    .menu-icon:hover {
        background-color: #555; /* Lighter color on hover */
    }
}