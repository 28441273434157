.order-conf-wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.order-conf-wrapper .body-small, .order-conf-wrapper .title-3 {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}

.order-conf-wrapper > .container > * {
    opacity: 0;
}

.ref-box {
    cursor: pointer;
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    border: 2px dashed #d9d9d9;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}