h1, h2, h3, h4 {
    font-weight: 600;
    letter-spacing: -2px;
}

.title-1, .title-2, .title-3, .title-4 {
    font-weight: 600;
    letter-spacing: -0.5px;
}

.body-small {
    color: #444;
    font-weight: 400;
}

@media (max-width: 500px) {
    h1 { font-size: 34px; letter-spacing: -1.5px; }
    h2 { font-size: 28px; letter-spacing: -1px; }
    h3 { font-size: 24px; letter-spacing: -1px; }
    .body-small { font-size: 14px; line-height: 130%; letter-spacing: 0; }
    .title-4 { font-size: 14px; }
    .title-3 { font-size: 16px; }
    .title-2 { font-size: 18px; }
    .title-1 { font-size: 22px; }
}

@media (min-width: 500px) {
    h1 { font-size: 34px; letter-spacing: -1.5px; }
    h2 { font-size: 28px; letter-spacing: -1px; }
    h3 { font-size: 24px; letter-spacing: -1px; }
    .body-small { font-size: 14px; line-height: 130%; }
    .title-4 { font-size: 14px; }
    .title-3 { font-size: 16px; }
    .title-2 { font-size: 18px; }
    .title-1 { font-size: 22px; }
}

@media (min-width: 768px) {
    h1 { font-size: 45px; letter-spacing: -2.5px; }
    h2 { font-size: 32px; letter-spacing: -1px; }
    h3 { font-size: 24px; letter-spacing: -1px; }
    .body-small { font-size: 16px; line-height: 130%; letter-spacing: -0.5px; }
    .title-3 { font-size: 18px; }
    .title-2 { font-size: 20px; }
    .title-1 { font-size: 24px; }
}

/* @media (min-width: 900px) {
    
}

@media (min-width: 1024px) {
    
}

@media (min-width: 1200px) {
    
} */