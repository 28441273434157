.toggle-button-group {
    display: flex;
  }
  
  .toggle-button {
    border: none;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 16px;
    margin-right: 8px;
    background-color: #f2f2f2;
    color: #333;
    cursor: pointer;
  }
  
  .toggle-button.selected {
    background-color: #fffcc0;
    color: black;
  }
  