/* CustomSelect.css */

.custom-select {
    width: 100%;
    max-width: 300px;
    position: relative;
    display: inline-block;
    padding: 5px 20px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  select {
    /* appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; */
    background: none;
    border: none;
    width: 100%;
    max-width: 300px;
    background-color: #d9d9d9;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 0;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
  
  .custom-select::after {
    content: '\25BC'; /* Downward arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #666;
  }
  
  /* Styles for dropdown options */
  .custom-select select option {
    padding: 10px;
    background-color: #f2f2f2;
    color: #333;
  }
  
  .custom-select select option:hover {
    background-color: #ddd;
  }
  